@import '../../App.module.scss';

.homeLabContainer {
  font-family: $font-roboto;
  line-height: $lineHeight;
  color: $gray;

  a {
    text-decoration: none;
  }
}

.serviceItemTitle {
  color: $primary-light;
  transition: color 0.6s ease;
}

.serviceItemTitle.hover,
.serviceItemTitle:hover {
  color: $primary;
}

.rightTriangle {
  font-size: 0.8rem;
}

.containerDropDown {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  cursor: pointer;
}

.containerDropDown__services {
  max-height: 0rem;
  transition: max-height 0.8s ease-in-out;
}

.containerDropDown__servicesActive {
  max-height: 75rem;
  transition: max-height 0.8s ease-in-out;
}

.containerDropDown__network {
  max-height: 0rem;
  transition: max-height 0.8s ease-in-out;
}

.containerDropDown__networkActive {
  max-height: 40rem;
  transition: max-height 0.8s ease-in-out;
}

.contentIcon {
  font-size: 0.8rem;
  padding-right: 0.5rem;
}

.portfolioFaqTitle {
  font-family: $font-urbanist;
  cursor: pointer;
}

.containerDropDown__faq {
  margin-left: 1rem;
  max-height: 0rem;
  transition: max-height 0.8s ease-in-out;
}

.containerDropDown__faqActive {
  margin-left: 1rem;
  max-height: 25rem;
  transition: max-height 0.8s ease-in-out;
}

.portfolioQ {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
}