@import '../../../App.module.scss';

.servicesContainer {
  margin: 0 1rem;
}

.serviceItemContainer {
  padding: 0.5rem;
}

.serviceItemTitle {
  color: $primary-light;
}

.serviceItemTitle.hover,
.serviceItemTitle:hover {
  color: $primary;
}

