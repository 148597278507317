@import "../App.module.scss";

.portfolioContainer {
	margin-top: 0rem;
	margin-bottom: 4rem;

	.titlePortfolio {
		font-family: $font-urbanist;
		font-size: 2rem;
		font-weight: bolder;
		padding-bottom: 8px;
		border-bottom: 1px solid $x-light-gray;
	}

	.porfolioDescription {
		font-family: $font-roboto;
		color: $gray;
		line-height: $lineHeight;
	}
}

.titlePortfolioItem {
	font-family: $font-urbanist;
	font-size: 1.3rem;
	letter-spacing: 0.5px;

	padding-top: 2rem;
	padding-bottom: 0.5rem;
}

.titlePortfolioItemContainer {
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	border: 3px solid $primary-light;
	border-radius: 6px;
}
