.experienceContainer {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	margin-bottom: 3rem;
}

@media (max-width: 688px) {
	.experienceContainer {
		margin-top: 0rem;
	}
}
