@import "../App.module.scss";

.homeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 3rem;
}

.aboutMeContainer {
	display: flex;
	flex-direction: column;

	.aboutMeContent {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			margin-left: 1rem;
			border-radius: 3px;
			box-shadow: $box-shadow;
		}
	}
}

.residence {
	margin-bottom: 3rem;
	padding: 0.8rem 1.3rem;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 30rem;

	font-family: $font-roboto;
	color: $secondary;

	border-radius: 5px;
	box-shadow: $box-shadow;
	background: linear-gradient(
		to right,
		#426876,
		#2b5566,
		#2b5566,
		#2b5566,
		#426876
	);

	.residenceItem {
		font-size: 0.9rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.location {
		font-size: 0.8rem;
		opacity: 0.8;
	}

	.flag {
		box-shadow: none;
		border-radius: 2.5px;
	}
}

.backgroundContainer {
	display: flex;
	flex-direction: column;
}

.contentHeader {
	font-family: $font-urbanist;
	font-size: 2rem;
	font-weight: bolder;
	border-bottom: 1px solid $x-light-gray;
	padding-bottom: 8px;
}

.content {
	font-family: $font-roboto;
	line-height: $lineHeight;
	color: $gray;
}

@media (max-width: 688px) {
	.aboutMeContainer {
		margin-top: 0rem;
		.aboutMeContent {
			display: flex;
			flex-direction: column-reverse;

			img {
				margin-top: 1rem;
			}
		}
	}

	.residence {
		width: 20rem;

		.residenceItem {
			display: flex;
			align-items: center;
		}
	}
}
