@import "../App.module.scss";

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerTitle {
	margin-top: 0.2rem;
	font-family: $font-urbanist;
	font-size: 2rem;
	color: $secondary;
}

.navList {
	margin-top: 0.9rem;
	display: flex;
	gap: 0.3rem;
	font-size: 0.8rem;
	font-family: $font-roboto;
	list-style-type: none;
	overflow-x: hidden;
	overflow: hidden;

	li {
		display: inline;
		padding: 0.4rem 0.7rem;
		border-radius: 5px;

		.navListElement {
			display: inline;
		}

		a {
			font-family: $font-urbanist;
			color: $x-light-gray;
			text-decoration: none;
			transition: 0.4s ease-out;
			letter-spacing: 1.4px;
		}

		a:hover,
		a.hover {
			color: $secondary;
			-webkit-transition: color 150ms linear;
			-ms-transition: color 150ms linear;
			transition: 0.4s ease-out;
		}

		a:active,
		a.active {
			color: $secondary;
			background-color: #144355;
			padding: 0.4rem 0.7rem;
			border-radius: 5px;
		}
	}
}

@media (max-width: 688px) {
	.nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.navList {
		display: flex;
		flex-direction: row;
		margin-left: -2rem;

		li {
			a:active,
			a.active {
				background-color: #1e5368;
			}
		}
	}
}
