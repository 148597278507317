@import "../../App.module.scss";

.jobContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

img {
	border-radius: 5px;
	box-shadow: $box-shadow;
}

.companyName {
	padding-top: 1rem;
	font-family: $font-urbanist;
	font-size: 1.5rem;
	font-weight: bold;
}

.jobTitle {
	font-family: $font-urbanist;
	font-size: 1.2rem;
}

.dates {
	width: 80%;
	padding-bottom: 8px;
	display: flex;
	justify-content: center;

	font-size: 0.9rem;
	font-family: $font-urbanist;
	color: $gray;
	border-bottom: 1px solid $x-light-gray;
}

.experience li {
	display: list-item;
	color: $gray;
	line-height: $lineHeight;
	font-family: $font-roboto;
}

@media (max-width: 688px) {
	.dates {
		display: block;
		float: none;
	}

	.experience li {
		text-align: left;
		margin-left: -1rem;
	}
}
