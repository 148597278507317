@import "../../App.module.scss";

.pjelContainer {
	padding-top: 1rem;

	a {
		text-decoration: none;
	}
}

.pjelContent {
	display: block;
	font-family: $font-roboto;
	color: $gray;
	line-height: $lineHeight;
}
.linkAuthContainer {
	text-align: center;
  a {
    font-weight: 600;
  }
}

.pjelLink {
	color: $primary-light;
	transition: 0.4s ease-out;
}

.pjelLink.hover,
.pjelLink:hover {
	color: $primary;
	transition: 0.4s ease-out;
}

.authContainer {
	padding-top: 0.6rem;
	font-family: $font-roboto;
}

.pjelContentAuth {
	display: block;
	padding: 0.2rem;
}

.authIcon {
	font-size: 0.9rem;
  color: $gray;
}

.authContent {
  font-size: 0.9rem;
  color: $gray;
}
