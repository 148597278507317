@import "../App.module.scss";

.header {
	width: 43rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
	padding-bottom: 1rem;

	color: $gray;
	font-family: $font-roboto;

	.headerTitle {
		font-family: $font-urbanist;
		font-size: 2rem;
		font-weight: bolder;
		padding-bottom: 8px;
		color: black;
		border-bottom: 1px solid $x-light-gray;
	}

	.headerContent {
		margin-top: 1rem;
		line-height: $lineHeight;
	}
}

.container {
	width: 30rem;
	height: 30rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.linkedIn {
	color: $primary-light;
	transition: color 0.6s ease;
	text-decoration: none;
}

.linkedIn:hover,
.linkedIn.hover {
	color: $primary;
}

.element {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.inputField {
	border: 0;
	box-shadow: none;
	height: 2rem;
	width: 21.7rem;
	padding-left: 1rem;

	border-left: 4px solid $primary;
	color: grey;
	text-align: center;

	transition: border-left 0.3s ease-out, color 0.3s ease-out;
}

.inputField:focus,
.inputField.focus {
	border-style: none;
	outline: none;

	border-left: 4px solid $primary-light;
	transition: border-left 0.3s ease-out, color 0.3s ease-out;
	color: black;

	text-align: center;
}

.inputField::placeholder,
.inputField:focus::placeholder {
	font-family: $font-roboto;
	font-weight: 500;
	color: $gray;
}

.submitButton {
	width: 8rem;
	height: 2.5rem;

	border-radius: 3px;
	border-style: none;

	background-color: $primary;
	color: $secondary;

	cursor: pointer;
	box-shadow: $box-shadow;
	transition: 0.6s;
}

.submitButton:hover,
.submitButton.hover {
	background-color: $primary-light;
	color: $secondary;
}

.messageArea {
	border: 0;
	box-shadow: none;
	padding-left: 1rem;

	width: 21.7rem;
	height: 10rem;

	border-left: 4px solid $primary;

	// Need to set the following three because off focus, it goes to
	// something weird...
	font-size: 0.83rem;
	font-family: $font-roboto;
	color: $gray;

	scrollbar-width: thin;
	scrollbar-color: $secondary $primary;

	transition: border-left 0.3s ease-out, color 0.3s ease-out;
}

.messageArea:focus,
.messageArea.focus {
	outline: none;

	border-left: 4px solid $primary-light;
	transition: border-left 0.3s ease-out, color 0.3s ease-out;
	color: black;

	font-family: $font-roboto;
}

.messageArea::placeholder,
.messageArea:focus::placeholder {
	text-align: center;
	color: $gray;

	font-weight: 500;
	font-family: $font-roboto;
}

.successState {
	color: #369736;
	font-weight: bold;
}

.errorState {
	color: #fd6262;
	font-weight: bold;
}

@media (max-width: 688px) {
	.container {
		width: -moz-fit-content;
		width: fit-content;
	}

	.header {
		width: -moz-fit-content;
		width: fit-content;
	}
}

@media (max-width: 688px) {
	.header {
		margin-top: 0rem;
	}
}
