@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

// Colors
$primary: #144355;
$primary-light: #277999;
$secondary: #fff8e2;
$gray: #4c4c4c;
$light-gray: #595959;
$x-light-gray: rgb(177, 176, 176);

// Fonts
$font-roboto: "Roboto", sans-serif;
$font-urbanist: "Urbanist", sans-serif;

// Other
$box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.5);
$lineHeight: 1.8;

// App.js
header {
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	background: linear-gradient(
		to right,
		#144355,
		#0e303d,
		#0e303d,
		#0e303d,
		#144355
	);
	// offset-x | offset-y | blur-radius | spread-radius | color
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.5);
	margin-bottom: 2rem;
}

.headerComp {
	width: 43rem;
	margin-left: auto;
	margin-right: auto;
}

.appBody {
	width: 43rem;
	min-height: calc(100vh - 9.9rem);
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 47rem) {
	.headerComp {
		width: -moz-fit-content;
		width: fit-content;

		text-align: center;
	}

	.appBody {
		width: -moz-fit-content;
		width: fit-content;
		padding: 1rem;
		text-align: center;
	}

	.otherComps {
		padding-top: 2rem;
	}
}

@media (max-width: 688px) {
	header {
		margin-bottom: 1rem;
	}
}
